import {
  CardTitle,
  CardDescription,
  CardHeader,
  CardContent,
  CardFooter,
  Card,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import React from "react";
import { useState, useRef } from "react";
import { Profile } from "./useFetchHomePosts";
import { useUpdateProfileMutation } from "./useUpdateProfileMutation";
import { useAuth } from "./AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";

type FormData = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  bio: string;
  profile_picture: File | null;
};

const ProfileInfosForm: React.FC<{ profile: Profile; onclick: () => void }> = ({
  profile,
  onclick,
}) => {
  const [formData, setFormData] = useState<FormData>({
    first_name: profile.first_name,
    last_name: profile.last_name,
    email: profile.user.email,
    phone: profile.phone,
    bio: profile.bio,
    profile_picture: null,
  });

  const fileInputRef = useRef(null);

  const { accessToken } = useAuth();

  const { toast } = useToast();

  const { mutate: updateProfile } = useUpdateProfileMutation();

  const queryClient = useQueryClient();

  const [errors, setErrors] = useState({ email: false, phone: false });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormData({ ...formData, profile_picture: e.target.files[0] });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    if (id === "phone") {
      const phoneRegex = /^\d{9}$/;
      setErrors({ ...errors, phone: !phoneRegex.test(value) });
    } else if (id === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      setErrors({ ...errors, email: !emailRegex.test(value) });
    }
  };

  const handleSumbit = () => {
    if (errors.email || errors.phone) {
      toast({
        variant: "destructive",
        title: "Upload Failed",
        description: "Invalid email or phone number format",
      });
      return;
    }
    const dataToSend = formData.profile_picture
      ? formData
      : { ...formData, profile_picture: undefined };
    return updateProfile(
      { formData: dataToSend, accessToken },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: (query) => {
              if (
                query.queryKey[0] === "fetchProfilePosts" &&
                query.queryKey[1] === profile.id.toString()
              ) {
                return true;
              }
              return false;
            },
          });
          onclick();
          toast({
            variant: "constructive",
            title: "Updated!",
          });
        },
        onError: (error) => {
          const err = error as { response?: any };
          toast({
            variant: "destructive",
            title: "Upload Failed",
            description: JSON.stringify(err.response.data),
          });
        },
      }
    );
  };

  return (
    <Card className="w-full max-w-3xl">
      <CardHeader>
        <div className="flex justify-end">
          <button onClick={onclick} className="p-2">
            <XIcon className="w-8 h-8" />
          </button>
        </div>
        <CardTitle>Profile</CardTitle>
        <CardDescription>Edit your profile information.</CardDescription>
      </CardHeader>
      <CardContent>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div className="space-y-2">
              <Label htmlFor="profile-picture">Profile Picture</Label>
              <Input
                id="profile_picture"
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="first-name">First Name</Label>
              <Input
                id="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="last-name">Last Name</Label>
              <Input
                id="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="bio">Bio</Label>
              <Textarea
                className="min-h-[100px]"
                id="bio"
                placeholder="Enter your bio"
                onChange={handleChange}
                value={formData.bio}
              ></Textarea>
            </div>

            <div className="space-y-2">
              <Label htmlFor="phone">Phone</Label>
              <Input
                id="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <button
          type="submit"
          onClick={handleSumbit}
          className="ml-auto text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5"
        >
          Save
        </button>
      </CardFooter>
    </Card>
  );
};

export default ProfileInfosForm;

function XIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
