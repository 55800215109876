import { AvatarImage, AvatarFallback, Avatar } from "@/components/ui/avatar";
import ImageIcon from "./ImageIcon";
import UsersIcon from "./UsersIcon";
import { Link } from "react-router-dom";
import { useProfile } from "./ProfileContext";
import { useState } from "react";
import ProfileInfosForm from "./ProfileInfosForm";
import { Profile } from "./useFetchHomePosts";
import { useDeleteAccount } from "./useDeleteAccountMutation";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

const ProfilePreview: React.FC<{ profile: Profile; imageNumber: number }> = ({
  profile,
  imageNumber,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { id: sessionProfileId } = useProfile();

  const {mutate: deleteAccount} = useDeleteAccount();

  const {accessToken} = useAuth();

  const navigate = useNavigate();

  const {toast} = useToast();

  const profileId = profile.id ? profile.id : sessionProfileId;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDeleteAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    deleteAccount({accessToken}, {
      onSuccess: () => {
        toast({
          variant: "constructive",
          title: "Account deleted successfully",
        });
        navigate(`/`);
      },
      onError: (error) => {
        const err = error as { response?: any };
        toast({
          variant: "destructive",
          title: "Couldn't delete account",
          description: JSON.stringify(err.response.data),
        });
      },
    });
    
  }

  return (
    <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4">
      <Link to={`/app/profile/${profileId}`}>
        <Avatar className="w-32 h-32 border">
          <AvatarImage alt="@shadcn" src={profile.profile_picture} />
          <AvatarFallback>AC</AvatarFallback>
        </Avatar>
      </Link>
      <div className="flex flex-col justify-center align-middle ml-4">
        <div className="flex justify-items-end">
          <Link to={`/app/profile/${profileId}`}>
            <h1 className="text-2xl font-bold">
              {profile.user.username} ({profile.first_name} {profile.last_name})
            </h1>
          </Link>
          {profileId === sessionProfileId && (
            <div>
              <button
                onClick={toggleModal}
                className="ml-4 text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5"
              >
                Edit Profile
              </button>
              <button
                onClick={handleDeleteAccount}
                className="ml-4 text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5"
              >
                Delete Profile
              </button>
            </div>
          )}
          
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {profile.bio}
        </p>
        <div className="flex space-x-4 mt-2">
          <div className="flex items-center space-x-1">
            <UsersIcon className="w-4 h-4" />
          </div>
          <div className="flex items-center space-x-1">
            <ImageIcon className="w-4 h-4" />
            <span>{imageNumber} images</span>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal h-screen w-screen ml-0">
          <div className="modal-content h-screen w-screen">
            <ProfileInfosForm onclick={toggleModal} profile={profile} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePreview;
