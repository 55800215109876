import { AvatarImage, Avatar } from "@/components/ui/avatar";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import ImageUploader from "./UploadPostModal";
import { useAuth } from "./AuthContext";
import { useProfile } from "./ProfileContext";

const Sidebar: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { signOut } = useAuth();
  const { user, profile_picture, id } = useProfile();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const handleSignOut = () => {
    signOut();
  };

  return (
    <aside className="sticky top-0 h-screen w-auto md:w-64 bg-white text-black flex flex-col items-center py-8">
      <img
        className="mx-auto h-40 w-auto hidden md:block"
        src={logo}
        alt="Ugram"
      />
      <nav className="flex flex-col items-start space-y-4 w-full px-8">
        <Link to="/app/home" className="flex items-center space-x-2 text-lg">
          <HomeIcon className="text-black h-6 w-6" />
          <span className="hidden md:inline">Home</span>
        </Link>
        <Link to="/app/search" className="flex items-center space-x-2 text-lg">
          <MicroscopeIcon className="text-black h-6 w-6" />
          <span className="hidden md:inline">Search</span>
        </Link>
        <a className="flex items-center space-x-2 text-lg" href="#">
          <HeartIcon className="text-black h-6 w-6" />
          <span className="hidden md:inline">Notifications</span>
        </a>
        <a className="flex items-center space-x-2 text-lg">
          <div>
            <nav>
              <button onClick={handleOpenModal}>
                <div className="flex items-center">
                  <PlusIcon className="text-black h-6 w-6" />
                  <span className="hidden md:inline">Create</span>
                </div>
              </button>
            </nav>
            {isModalOpen && (
              <div className="modal h-screen">
                <div className="modal-content h-screen">
                  <span className="close" onClick={handleCloseModal}>
                    ×
                  </span>
                  <ImageUploader onSumbit={handleCloseModal} />
                </div>
              </div>
            )}
          </div>
        </a>
        <Link
          to={`/app/profile/${id}`}
          className="flex items-center space-x-2 text-lg"
        >
          <Avatar className="h-9 w-9">
            <AvatarImage alt="Profile" src={profile_picture} />
          </Avatar>
          <span className="hidden md:block">{user.username}</span>
        </Link>
      </nav>
      <div className="flex-column mt-auto w-full px-8">
        <a className="flex items-center space-x-2 text-lg mb-4" href="#">
          <SettingsIcon className="text-black h-6 w-6" />
          <span className="hidden md:block">Settings</span>
        </a>

        <button
          className="flex items-center space-x-2 text-lg"
          onClick={handleSignOut}
        >
          <LogOutIcon className="text-black h-6 w-6" />
          <span className="hidden md:block">Logout</span>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;

function HomeIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  );
}

function MicroscopeIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 18h8" />
      <path d="M3 22h18" />
      <path d="M14 22a7 7 0 1 0 0-14h-1" />
      <path d="M9 14h2" />
      <path d="M9 12a2 2 0 0 1-2-2V6h6v4a2 2 0 0 1-2 2Z" />
      <path d="M12 6V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3" />
    </svg>
  );
}

function HeartIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
    </svg>
  );
}

function PlusIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  );
}

function SettingsIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
      <circle cx="12" cy="12" r="3" />
    </svg>
  );
}

function LogOutIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline points="16 17 21 12 16 7" />
      <line x1="21" x2="9" y1="12" y2="12" />
    </svg>
  );
}
