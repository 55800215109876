import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import "../styles/authentification.css";
import React, { useState } from "react";
import { useSignUpMutation } from "./useSignUpMutation";
import { useAuth } from "./AuthContext";
import { useToast } from "@/components/ui/use-toast";

type FormFields = {
  username: string;
  email: string;
  password: string;
  profile: {
    first_name: string;
    last_name: string;
    phone: string;
  };
};

function SignUp() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    profile: {
      first_name: "",
      last_name: "",
      phone: "",
    },
  });

  const { mutate: SignUp } = useSignUpMutation();

  const { signIn } = useAuth();
  const { toast } = useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name in formData.profile) {
      setFormData({
        ...formData,
        profile: {
          ...formData.profile,
          [e.target.name]: e.target.value,
        },
      });
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateField = (fieldName: keyof FormFields, errorMessage: any) => {
    if (!formData[fieldName]) {
      toast({
        variant: "destructive",
        title: errorMessage,
      });
      return false;
    }
    return true;
  };

  const validateEmail = (email: any) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const handleSumbit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !validateField("username", "Username Field Required") ||
      !validateField("email", "Email Field Required") ||
      !validateField("password", "Password Field Required") ||
      !validateField("profile", "Profile Information Fields Required")
    ) {
      return;
    }
    if (!validateEmail(formData.email)) {
      toast({
        variant: "destructive",
        title: "Please enter a valid email address.",
      });
      return;
    }
    SignUp(formData, {
      onSuccess: () => {
        signIn({ email: formData.email, password: formData.password });
      },
      onError: (error) => {
        const err = error as { response?: any };
        toast({
          variant: "destructive",
          title: "Sign up Failed",
          description: JSON.stringify(err.response.data),
        });
      },
    });
  };

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/");
  };
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-20 w-auto" src={logo} alt="Ugram" />
      </div>
      <form>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                First name
              </label>
              <input
                name="first_name"
                onChange={handleChange}
                type="text"
                id="first_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-indigo-500 light:focus:border-indigo-500"
                placeholder="John"
                required
              />
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Last name
              </label>
              <input
                name="last_name"
                onChange={handleChange}
                type="text"
                id="last_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-indigo-500 light:focus:border-indigo-500"
                placeholder="Doe"
                required
              />
            </div>
            <div>
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Username
              </label>
              <input
                name="username"
                onChange={handleChange}
                type="text"
                id="company"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-indigo-500 light:focus:border-indigo-500"
                placeholder="JohnDoe"
                required
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
              >
                Phone number
              </label>
              <input
                name="phone"
                onChange={handleChange}
                type="tel"
                id="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-indigo-500 light:focus:border-indigo-500"
                placeholder="123456789"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
            >
              Email address
            </label>
            <input
              name="email"
              onChange={handleChange}
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-indigo-500 light:focus:border-indigo-500"
              placeholder="john.doe@company.com"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
            >
              Password
            </label>
            <input
              name="password"
              onChange={handleChange}
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 light:bg-gray-700 dark:border-gray-600 light:placeholder-gray-400 light:text-white light:focus:ring-indigo-500 light:focus:border-indigo-500"
              placeholder="•••••••••"
              required
            />
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={handleCancel}
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSumbit}
              className="text-white bg-indigo-500 hover:bg-indigo-500 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
export default SignUp;
