import { AvatarImage,  Avatar } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { SendHorizontal } from 'lucide-react';
import { comment } from "./useFetchHomePosts";


const CommentSection = ({
    comments,
    handleCommentSubmit,
    handleInputChange
}: {
    comments: comment[],
    handleCommentSubmit: any,
    handleInputChange: any
}
) => {
    return (
        <div className="w-full max-w-2xl mx-auto">
            <div className="mt-6">
                <div className="space-y-6 overflow-y-auto  max-h-[400px]">
                    {comments.map((comment, index) => (
                        <div key={index} className="flex items-start gap-4">
                            <Avatar className="h-10 w-10 shrink-0 rounded-full">
                                <AvatarImage alt={""} src={comment.profile.profile_picture} />
                            </Avatar>
                            <div className="flex-1 space-y-1">
                                
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <div className="font-medium">@{comment.profile.user.username}</div>
                                        <div className="text-gray-500 text-sm dark:text-gray-400">{comment.created_at}</div>
                                    </div>
                                    <Button className="h-8 w-8" size="icon" variant="ghost">
                                        <TrashIcon className="h-4 w-4" />
                                        <span className="sr-only">Delete comment</span>
                                    </Button>

                                </div>
                                <p>{comment.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-row space-y-2">
                    <Textarea
                        onChange={handleInputChange}
                        className="w-full resize-none rounded-lg border border-gray-200 p-3 text-sm shadow-sm transition-colors focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100 dark:focus:border-gray-600 dark:focus:ring-gray-600"
                        placeholder="Add a comment..."
                    ></Textarea>
                    <div className="flex justify-end">
                        {/* <button onClick={handleCommentSubmit} className="mb-2 focus:outline-none border-none bg-transparent text-blue-600">Send</button> */}
                        <SendHorizontal onClick={handleCommentSubmit}  className="cursor-pointer"/>
                    </div>

                </div>
            </div>
        </div>
    )
}

function TrashIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 6h18" />
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        </svg>
    )
}

export default CommentSection;