import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import * as Sentry from '@sentry/react';

interface PostProps {
  image: File | null;
  hashtags: string;
  caption: string;
  mentions: string;
}

export const useCreatePostMutation = () => {
  Sentry.captureMessage('Post creation initiated', 'info');
  return useMutation({
    mutationFn: async ({
      postData,
      accessToken,
    }: {
      postData: PostProps;
      accessToken: string | null;
    }) => {
      return axios.post(`${config.backendUrl}/app/posts/`, postData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
};
