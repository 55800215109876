import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import * as Sentry from '@sentry/react';

export const useLikePost = () => {
    Sentry.captureMessage('Post like initiated', 'info');
    return useMutation({
        mutationFn: async ({ postId, accessToken }: { postId: string | undefined, accessToken: string | null }) => {
            return axios.post(`${config.backendUrl}/app/posts/${postId}/like`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });
        },
    });
};
