import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import * as Sentry from '@sentry/react';

interface ProfileInfos {
  first_name: string;
  last_name: string;
  bio: string;
  // image: File | null;
}

export const useUpdateProfileMutation = () => {
  Sentry.captureMessage("Profile updated", 'info');
  return useMutation({
    mutationFn: async ({
      formData,
      accessToken,
    }: {
      formData: ProfileInfos;
      accessToken: string | null;
    }) => {
      return await axios.put(
        `${config.backendUrl}/users/profile/edit`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  });
};
