import './App.css';
import Router from './components/component/Router';
import { QueryClient } from '@tanstack/react-query';
import './lib/SentryLogger.ts';
import { ErrorBoundary } from '@sentry/react';

function App() {
  const queryClient = new QueryClient();

  return (
      <ErrorBoundary fallback={<div>An error has occurred</div>}>
        <Router queryClient={queryClient}/>
      </ErrorBoundary>
  );
}

export default App;