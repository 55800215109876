import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "../../config";
import * as Sentry from '@sentry/react';

export interface UserData {
    id: number;
    username: string;
    email: string;
}

export interface ProfileData {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture: string;
    bio: string;
    phone: string;
    email: string;
    created_at: string;
    user: UserData;
}

export const useFetchSessionProfileData = (accessToken : string | null) => {
    Sentry.captureMessage("Fetching profile info", 'info');
    return useQuery({
        queryKey: ["fetchProfileData"],
        queryFn: async () => {
            const response = await axios.get(`${config.backendUrl}/app/profile`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                
            });
            return response.data as ProfileData;
        },
        enabled: !!accessToken,
    });
}