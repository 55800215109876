import React from "react";


const EmptyNotice: React.FC<{ message: string }> = ({ message }) => {
    return (
        <div className="flex justify-center items-center h-full w-full">
            <div className="text-2xl text-gray-500">{message}</div>
        </div>
    );
}

export default EmptyNotice;