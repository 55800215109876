import React, { useState } from "react";
import ProfilePreview  from "./ProfilePreview";
import { Input } from "@/components/ui/input";
import { useFetchProfiles } from "./useFetchProfiles";
import { useAuth } from "./AuthContext";
import LoadingSpinner from "./LoadingSpinner";
import EmptyNotice from "./EmptyNotice";

const Search: React.FC = () => {
  const { accessToken } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const { data: profiles, isLoading, error } = useFetchProfiles(accessToken);

  const filteredProfiles = profiles?.filter((profile) =>
    profile.user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <div className="text-red-500">Error: {error.message}</div>
      </div>
    );
  }

  if (!profiles || profiles.length === 0) {
    return (
      <EmptyNotice message="No profiles to show"/>
    );
  }

  return (
    <div className="w-full flex flex-col items-center p-4">
      <Input
        type="text"
        placeholder="Search by Username"
        className="w-full max-w-md p-2 mb-4 border rounded focus:outline-none focus:border-blue-500"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="w-full max-w-md overflow-y-auto max-h-screen">
        {(searchQuery === "" ? profiles : filteredProfiles)?.map((profile) => (
          <div key={profile.user.username} className="mb-4">
            <ProfilePreview
              profile={profile}
              imageNumber={profile.imageNumber}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Search;
