import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { HomePost } from "./useFetchHomePosts";
import config from "../../config";
import * as Sentry from '@sentry/react';


export const useFetchPostInfos = ({ postId, accessToken } : { postId: string | undefined, accessToken: string | null }) => {
    Sentry.captureMessage("Fetching post's info", 'info');
    return useQuery({
        queryKey: ["fetchPostInfos", postId],
        queryFn: async () => {
            const response = await axios.get(`${config.backendUrl}/app/posts/${postId}`, 
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            return response.data as HomePost;
        },
        enabled: !!accessToken,
    });
}