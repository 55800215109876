
import React from 'react';
import { useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


interface ProtectedRouteProps {
  children: React.ReactNode;
}


const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const navigate = useNavigate();

    const { accessToken } = useAuth();

    useEffect(() => {
        if (!Cookies.get('refresh')){
            navigate('/');
        }
    }, [accessToken, navigate])

    return (
        <> {children} </>
    )
};

export default ProtectedRoute;


