import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: "https://d0c21ac1bde8f13c8fd850c625cf8981@o4506911351177216.ingest.us.sentry.io/4506911355305984",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});