import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import * as Sentry from '@sentry/react';

interface SignInCredentials {
    email: string;
    password: string;
}

export const useSignInMutation = () => {
    Sentry.captureMessage("Sign-in", 'info');
    return useMutation({
        mutationFn: async (credentials: SignInCredentials) => {
            return axios.post(`${config.backendUrl}/users/login`, 
            credentials,
            {
                withCredentials: true,
            });
        } 
    });
}
    
