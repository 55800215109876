import { Loader2 } from 'lucide-react';

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
        <Loader2 className="h-8 w-8 animate-spin text-zinc-800" />
    </div>
  );
}

export default LoadingSpinner;