import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import * as Sentry from '@sentry/react';


export const useRefreshMutation = () => {
    Sentry.captureMessage("Refresh", 'info');
    return useMutation({
        mutationFn: async (refresh: string | undefined) => {
            return await axios.post(
                `${config.backendUrl}/users/token/refresh`,
                {
                    refresh: refresh,
                },
                {
                    withCredentials: true,
                }
            )
        }
    });
};