import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import * as Sentry from '@sentry/react';

export const useCommentPost = () => {
    Sentry.captureMessage('Post comment initiated', 'info');
    return useMutation({
        mutationFn: async ({ postId, content, accessToken }: { postId: string | undefined, content: string, accessToken: string | null }) => {
            return axios.post(`${config.backendUrl}/app/posts/${postId}/comment`, { content : content}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });
        },
    });
}