import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "../../config";
import * as Sentry from '@sentry/react';

export interface UserData {
    id: number;
    username: string;
    email: string;
}

export interface Profiles {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture: string;
    bio: string;
    imageNumber: number
    phone: string;
    email: string;
    created_at: string;
    user: UserData;
}

export const useFetchProfiles = (accessToken : string | null) => {
    Sentry.captureMessage("Fetching profiles", 'info');
    return useQuery({
        queryKey: ["fetchProfiles"],
        queryFn: async () => {
            const response = await axios.get(`${config.backendUrl}/app/profiles/list`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                
            });
            return response.data as Profiles[];
        },
        enabled: !!accessToken,
    });
}