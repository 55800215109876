import React, { useRef, useState } from "react";
import { useCreatePostMutation } from "./useCreatePostMutation";
import { useAuth } from "./AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";
import { useProfile } from "./ProfileContext";

interface ImageUploaderData {
  image: File | null;
  hashtags: string;
  caption: string;
  mentions: string;
}

interface ImageUploaderProps {
  onSumbit: () => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onSumbit }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [resizeOption, setResizeOption] = useState<string>("original");

  const { mutate: createPost } = useCreatePostMutation();
  const { id } = useProfile();
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [postData, setPostData] = useState<ImageUploaderData>({
    image: null,
    hashtags: "",
    caption: "",
    mentions: "",
  });

  const { accessToken } = useAuth();

  const handleInputsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    setPostData({
      ...postData,
      [id]: value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setPostData({
      ...postData,
      image: file,
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target?.result as string;
        
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          let width = image.width;
          let height = image.height;

          switch (resizeOption) {
            case "small":
              width = 300;
              height = 300;
              break;
            case "medium":
              width = 600;
              height = 600;
              break;
            case "large":
              width = 1200;
              height = 1200;
              break;
            case "original":
            default:
              break;
          }
          
          canvas.width = width;
          canvas.height = height;
          
          ctx?.drawImage(image, 0, 0, width, height);
          
          const resizedImageData = canvas.toDataURL('image/jpeg');
          
          setImageSrc(resizedImageData);
        };
      };
      
      reader.readAsDataURL(file);
    } else {
      setImageSrc(null);
    }
  };

  const handleResizeOptionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setResizeOption(event.target.value);
  };

  const handleCameraCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  
      const video = document.createElement('video');
      document.body.appendChild(video);
      video.srcObject = stream;
      video.play();
  
      video.onloadedmetadata = () => {
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "captured-image.jpg", {
              type: "image/jpeg",
            });
  
            setPostData({
              ...postData,
              image: file,
            });
  
            const reader = new FileReader();
            reader.onload = () => {
              setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
          }
  
          stream.getTracks().forEach((track) => {
            track.stop();
          });
  
          document.body.removeChild(video);
        }, 'image/jpeg');
      };
    } catch (error) {
      console.error('Error capturing image from camera:', error);
    }
  };
  

  const handleImagePreviewClick = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      uploadInputRef.current?.click();
    }
  };

  const handleCreatePost = () => {
    return createPost(
      { postData, accessToken },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: (query) => {
              if (
                query.queryKey[0] === "fetchProfilePosts" &&
                query.queryKey[1] === id.toString()
              ) {
                return true;
              }
              if (query.queryKey[0] === "fetchHomePosts") {
                return true;
              }
              return false;
            },
          });
          onSumbit();
          toast({
            variant: "constructive",
            title: "Posted!",
          });
        },
        onError: (error) => {
          const err = error as { response?: any };
          toast({
            variant: "destructive",
            title: "Upload Failed",
            description: JSON.stringify(err.response.data),
          });
        },
      }
    );
  };

  return (
    <section className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4">
      <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden items-center">
        <div className="px-4 py-8">
          <div
            id="image-preview"
            className={`max-w-sm p-6 mb-4 bg-gray-100 ${
              postData.image ? "" : "border-dashed border-2 border-gray-400"
            } rounded-lg items-center mx-auto text-center cursor-pointer`}
          >
            <input
              id="upload"
              type="file"
              className="hidden"
              accept=".png, .jpg, .jpeg"
              ref={uploadInputRef}
              onChange={handleFileChange}
            />
            {imageSrc ? (
              <img
                src={imageSrc}
                className="max-h-48 rounded-lg mx-auto"
                alt="Image preview"
                onClick={handleImagePreviewClick}
              />
            ) : (
              <label htmlFor="upload" className="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-8 h-8 text-gray-700 mx-auto mb-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                  />
                </svg>
                <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-700">
                  Upload picture
                </h5>
                <p className="font-normal text-sm text-gray-400 md:px-6">
                  Choose photo to upload{" "}
                </p>
                <p className="font-normal text-sm text-gray-400 md:px-6">
                  should be in <b className="text-gray-600">JPG or PNG</b>{" "}
                  format.
                </p>
                <span id="filename" className="text-gray-500 bg-gray-200 z-50">
                  {postData.image?.name || ""}
                </span>
              </label>
            )}
          </div>

          <div className="mt-4">
            <label htmlFor="resizeOption" className="block text-sm font-medium text-gray-700">
              Resize Option
            </label>
            <select
              id="resizeOption"
              value={resizeOption}
              onChange={handleResizeOptionChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="original">Original Size</option>
              <option value="small">Small (300x300)</option>
              <option value="medium">Medium (600x600)</option>
              <option value="large">Large (1200x1200)</option>
            </select>
          </div>

          <div className="flex items-center justify-center mt-4">
            <button
              className="text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 flex items-center justify-center cursor-pointer"
              onClick={handleCameraCapture}
            >
              Capture Photo
            </button>
          </div>

          <div className="mt-4">
            <label
              htmlFor="hashtags"
              className="block text-sm font-medium text-gray-700"
            >
              Hashtags
            </label>
            <textarea
              id="hashtags"
              value={postData.hashtags}
              onChange={handleInputsChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="mentions"
              className="block text-sm font-medium text-gray-700"
            >
              Mentions
            </label>
            <textarea
              id="mentions"
              value={postData.mentions}
              onChange={handleInputsChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="caption"
              className="block text-sm font-medium text-gray-700"
            >
              caption
            </label>
            <textarea
              id="caption"
              value={postData.caption}
              onChange={handleInputsChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <br></br>
          <div className="flex items-center justify-center">
            <div className="w-full">
              <label className="w-full text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 flex items-center justify-center mr-2 mb-2 cursor-pointer">
                <button className="text-center ml-2" onClick={handleCreatePost}>
                  Post
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageUploader;
