import ProfilePostCard from "./ProfilePostCard";
import { useAuth } from "./AuthContext";
import { useFetchProfilePosts } from "./useFetchProfilePosts";
import { useParams } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import EmptyNotice from "./EmptyNotice";
import React from "react";
import ProfilePreview from "./ProfilePreview";

const Profile: React.FC = () => {
  const { profileId } = useParams<{ profileId: string }>();

  const { accessToken } = useAuth();

  const {
    data: userPosts,
    isLoading,
    error,
  } = useFetchProfilePosts({ profileId, accessToken });
  

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    const err = error as { response?: any };
    return (
      <div className="flex justify-center items-center h-full w-full">
        <div>Error: {JSON.stringify(err.response.data.error)}</div>
      </div>
    );
  }

  const hasPosts = userPosts && userPosts.posts.length > 0;

  return (
    <div className="flex-1 overflow-y-auto p-5 flex flex-col items-center">
      <div className="w-full">
        <div className="flex justify-center">
          <div className="w-full max-w-6xl px-4 sm:px-6 lg:px-8 py-8">
            {/* wafen */}
            {userPosts && (
              <ProfilePreview
                profile={userPosts.profile}
                imageNumber={userPosts.profile.imageNumber}
              />
            )}

            {hasPosts ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 mt-8 justify-center">
                {userPosts.posts?.map((post) => (
                  <ProfilePostCard image={post.image} postId={post.id} key={post.id} />
                ))}
              </div>
            ) : (
              <EmptyNotice message="No posts to show !" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;


