import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import Layout from "./Layout";
import Profile from "./Profile";
import Search from "./Search";
import Post from "./PostInfos";
import SignUp from "./Signup";
import { AuthProvider } from "./AuthContext";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import React from "react";
import Layout_2 from "./Layout_2";
import ProtectedRoute from "./ProtectedRoute";
import { ProfileProvider } from "./ProfileContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Login from "./Login";

const BrowserRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <GoogleOAuthProvider clientId="638895685263-12m7lnlt3mj20san6sm08v833m8ud4vm.apps.googleusercontent.com">
        <AuthProvider>
          <Layout_2 />
        </AuthProvider>
      </GoogleOAuthProvider>
    ),
    children: [
      { path: "/", element: <Login /> },
      { path: "/signup", element: <SignUp /> },
    ],
  },
  {
    path: "/app",
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <ProfileProvider>
            <Layout />
          </ProfileProvider>
        </ProtectedRoute>
      </AuthProvider>
    ),
    children: [
      // { path: "/", element: <Login /> },
      { path: "/app/home", element: <Home /> },
      { path: "/app/profile/:profileId", element: <Profile /> },
      { path: "/app/post/:postId", element: <Post /> },
      { path: "/app/search", element: <Search /> },
    ],
  },
]);

interface RouterProps {
  queryClient: QueryClient;
}

const Router: React.FC<RouterProps> = ({ queryClient }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={BrowserRouter} />
    </QueryClientProvider>
  );
};

export default Router;
