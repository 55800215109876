import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useFetchSessionProfileData } from "./useFetchProfileData";
import { useAuth } from "./AuthContext";


interface UserData {
    id: number;
    username: string;
    email: string;
}

interface ProfileContextType {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture: string;
    bio: string;
    phone: string;
    email: string;
    created_at: string;
    user: UserData;
}

const ProfileContext = createContext<ProfileContextType | null>(null);

export const ProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [profileData, setProfileData] = useState<ProfileContextType>({
    id: 0,
    first_name: "",
    last_name: "",
    profile_picture: "",
    bio: "",
    phone: "",
    email: "",
    created_at: "",
    user: {
        id: 0,
        username: "",
        email: "",
    }
});

  const { accessToken } = useAuth();

  const { data } = useFetchSessionProfileData(accessToken);

  useEffect(() => {
    if (data) {
      setProfileData(data);
    }
  }, [data]);

  return (
    <ProfileContext.Provider
      value={{
        id: profileData.id,
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        profile_picture: profileData.profile_picture,
        bio: profileData.bio,
        phone: profileData.phone,
        email: profileData.email,
        created_at: profileData.created_at,
        user: {
            id: profileData.user.id,
            username: profileData.user.username,
            email: profileData.user.email,
        }
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () =>
  useContext(ProfileContext) as ProfileContextType;
