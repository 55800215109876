import React from "react";
import { HomePost, comment } from "./useFetchHomePosts";
import { Link } from "react-router-dom";
import { useLikePost } from "./useLikePostMutation";
import { useAuth } from "./AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useState } from "react";
import { useProfile } from "./ProfileContext";
import { useCommentPost } from "./useCommentPostMutation";
import CommentSection from "./CommentSection";

const PostCard: React.FC<{ item: HomePost }> = ({ item }) => {
  // const { id: sessionProfileId } = useProfile();
  const { mutate: likePost } = useLikePost();

  const { accessToken } = useAuth();

  const [isLiked, setIsLiked] = useState(false);

  const [likes, setLikes] = useState(0);

  const queryClient = useQueryClient();

  const {
    id: sessionProfileId,
    first_name,
    last_name,
    profile_picture,
    user,
  } = useProfile();

  const { mutate: commentPost } = useCommentPost();

  const [content, setContent] = useState("");

  const [comments, setComments] = useState<comment[]>([]);

  useEffect(() => {
    if (item && item.post.id) {
      setIsLiked(item.post.is_liked);
      setLikes(item.post.likes_count);
      setComments(item.post.comments);
    }
  }, [item]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const handleLike = () => {
    const postId: string | undefined = item.post.id.toString();
    if (item && item.post.id) {
      likePost(
        { postId, accessToken },
        {
          onSuccess: () => {
            setIsLiked(!isLiked);
            setLikes((prevLikes) => (isLiked ? prevLikes - 1 : prevLikes + 1));
            queryClient.invalidateQueries({
              predicate: (query) => {
                if (
                  query.queryKey[0] === "fetchPostInfos" &&
                  query.queryKey[1] === item.post.id
                ) {
                  return true;
                }
                if (query.queryKey[0] === "fetchHomePosts") {
                  return true;
                }
                return false;
              },
            });

            console.log("Post liked successfully");
          },
          onError: (error) => {
            console.log("Error liking post: ", error);
          },
        }
      );
      //setIsLiked(!isLiked);
    } else {
      console.log("No post ID found");
    }
  };

  const handleCommentSubmit = () => {
    if (item && item.post.id) {
      const postId: string | undefined = item.post.id.toString();
      commentPost(
        { postId, content, accessToken },
        {
          onSuccess: () => {
            setComments((prevComments) => [
              ...prevComments,
              {
                content,
                created_at: new Date().toISOString(),
                profile: {
                  id: sessionProfileId,
                  phone: "",
                  bio: "",
                  first_name: first_name,
                  last_name: last_name,
                  profile_picture: profile_picture,
                  imageNumber: 0,
                  user: { id: 0, username: user.username, email: "" },
                },
              },
            ]);
            setContent("");
            queryClient.invalidateQueries({
              predicate: (query) => {
                if (
                  query.queryKey[0] === "fetchPostInfos" &&
                  query.queryKey[1] === item.post.id
                ) {
                  return true;
                }
                return false;
              },
            });
            console.log("Comment posted successfully");
          },
          onError: (error) => {
            console.log("Error posting comment: ", error);
          },
        }
      );
    } else {
      console.log("No post ID found");
    }
  };

  const date: string = new Date(item.post.created_at).toLocaleDateString();
  const time: string = new Date(item.post.created_at).toLocaleTimeString(
    "en-US"
  );
  const dateTime: string = `${date} ${time}`;

  return (
    <div>
      <div className="bg-gray-100 p-4">
        <div className="bg-white border rounded-sm">
          <div className="flex justify-between">
            <div className="flex items-center px-4 py-3">
              <Link to={`/app/profile/${item.profile.id}`}>
                <img
                  className="h-8 w-8 rounded-full"
                  src={item.profile.profile_picture}
                />
              </Link>

              <div className="ml-3 ">
                <Link
                  to={`/app/profile/${item.profile.id}`}
                  className="text-sm font-semibold antialiased block leading-tight"
                >
                  {item.profile.first_name} {item.profile.last_name}
                </Link>
                <span className="text-gray-600 text-xs block font-medium">
                  {dateTime}
                </span>
              </div>
            </div>
          </div>

          <Link to={`/app/post/${item.post.id}`}>
            <img src={item.post.image} alt="post" className="w-full" />
          </Link>

          <div className="flex items-center justify-between mx-4 mt-3 mb-2">
            <div className="flex gap-5">
              <button onClick={handleLike} className="like-button">
                <svg
                  fill={isLiked ? "#ff0000" : "#262626"}
                  height="24"
                  viewBox="0 0 48 48"
                  width="24"
                >
                  <path d="M34.6 6.1c5.7 0 10.4 5.2 10.4 11.5 0 6.8-5.9 11-11.5 16S25 41.3 24 41.9c-1.1-.7-4.7-4-9.5-8.3-5.7-5-11.5-9.2-11.5-16C3 11.3 7.7 6.1 13.4 6.1c4.2 0 6.5 2 8.1 4.3 1.9 2.6 2.2 3.9 2.5 3.9.3 0 .6-1.3 2.5-3.9 1.6-2.3 3.9-4.3 8.1-4.3m0-3c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5.6 0 1.1-.2 1.6-.5 1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"></path>
                </svg>
              </button>
              <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24">
                <path
                  clipRule="evenodd"
                  d="M47.5 46.1l-2.8-11c1.8-3.3 2.8-7.1 2.8-11.1C47.5 11 37 .5 24 .5S.5 11 .5 24 11 47.5 24 47.5c4 0 7.8-1 11.1-2.8l11 2.8c.8.2 1.6-.6 1.4-1.4zm-3-22.1c0 4-1 7-2.6 10-.2.4-.3.9-.2 1.4l2.1 8.4-8.3-2.1c-.5-.1-1-.1-1.4.2-1.8 1-5.2 2.6-10 2.6-11.4 0-20.6-9.2-20.6-20.5S12.7 3.5 24 3.5 44.5 12.7 44.5 24z"
                  fillRule="evenodd"
                ></path>
              </svg>
              <svg fill="#262626" height="24" viewBox="0 0 48 48" width="24">
                <path d="M47.8 3.8c-.3-.5-.8-.8-1.3-.8h-45C.9 3.1.3 3.5.1 4S0 5.2.4 5.7l15.9 15.6 5.5 22.6c.1.6.6 1 1.2 1.1h.2c.5 0 1-.3 1.3-.7l23.2-39c.4-.4.4-1 .1-1.5zM5.2 6.1h35.5L18 18.7 5.2 6.1zm18.7 33.6l-4.4-18.4L42.4 8.6 23.9 39.7z"></path>
              </svg>
            </div>
          </div>

          <div className="font-semibold text-sm mx-4">{likes} likes</div>

          <div>
            {item.post.caption && (
              <div className="flex text-sm mx-4 mt-1">
                <button className="font-bold">
                  {item.profile.user.username}:
                </button>
                <div className="ml-2">{item.post.caption}</div>
              </div>
            )}

            {item.post.mentions.length > 0 && (
              <button className="font-semibold text-sm mx-4 mt-2 mb-4 text-blue-500">
                {item.post.mentions.join(" ")}
              </button>
            )}

            {item.post.hashtags.length > 0 && (
              <div className="font-semibold text-sm mx-4 mb-4 text-blue-500">
                {item.post.hashtags.join(" ")}
              </div>
            )}
          </div>
          <CommentSection
            comments={comments}
            handleCommentSubmit={handleCommentSubmit}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PostCard;
