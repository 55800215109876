import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import * as Sentry from '@sentry/react';


interface ProfileData {
    first_name: string;
    last_name: string;
}

interface SignUpData {
    username: string;
    password: string;
    email: string;
    profile: ProfileData;

}


export const useSignUpMutation = () => {
    Sentry.captureMessage("Sign-up", 'info');
    Sentry.captureMessage("Sign-in", 'info');
    return useMutation({
        mutationFn: async (formData: SignUpData) => {
            return axios.post(`${config.backendUrl}/users/signup`, formData);
        } 
    });
}