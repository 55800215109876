const backendUrl = import.meta.env.VITE_BACKEND_URL;

interface Config {
  backendUrl: string | "http://localhost:8000"
}

const config: Config = {
  backendUrl,
};

export default config;