import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import * as Sentry from '@sentry/react';


export const useSignOutMutation = () => {
    Sentry.captureMessage("Sign-out", 'info');
    return useMutation({
        mutationFn: async (refresh: string | undefined) => {
            return axios.post(`${config.backendUrl}/users/signout`, 
            {
                refresh: refresh,
            },
            {
                withCredentials: true,
            });
        } 
    });
}