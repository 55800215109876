import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import * as Sentry from '@sentry/react';



export const useDeletePost = () => {
    Sentry.captureMessage('Post deletion initiated', 'info');
    return useMutation({
        mutationFn: async ( {postId, accessToken}: { postId: string | undefined, accessToken: string | null} ) => {
            return axios.delete(`${config.backendUrl}/app/posts/${postId}/delete`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",

                },
            });
        },
    }) ;
};