import PostCard from "./HomePostCard";
import { useFetchHomePosts } from "./useFetchHomePosts";
import { useAuth } from "./AuthContext";
import { Input } from "@/components/ui/input";
import LoadingSpinner from "./LoadingSpinner";
import EmptyNotice from "./EmptyNotice";
import { useState } from "react";
import { HomePost } from "@/components/component/useFetchHomePosts";
import TrendingHashtag from "./trending-hashtags";

const Home = () => {
  const { accessToken } = useAuth();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { data: postItems, isLoading, error } = useFetchHomePosts(accessToken);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const getTrendingHashtags = (posts: HomePost[] | undefined) => {
    if (!posts) return new Map<string, number>();
    const hashtagsMap = new Map<string, number>();
    posts.forEach((post) => {
      post.post.hashtags.forEach((hashtag) => {
        hashtagsMap.set(
          hashtag,
          (hashtagsMap.get(hashtag) || 0) + 1
        );
      });
    });
    return hashtagsMap;
  }

  const getSuggestions = (posts: HomePost[] | undefined, query: string) => {
    if (!query || !posts) return [];
    const suggestionsSet = new Set<string>();
    posts.forEach((post) => {
      if (post.post.caption.toLowerCase().startsWith(query.toLowerCase())) {
        suggestionsSet.add(post.post.caption);
      }
      post.post.hashtags.forEach((hashtag) => {
        if (hashtag.toLowerCase().startsWith(query.toLowerCase())) {
          suggestionsSet.add(hashtag);
        }
      });
    });
    return Array.from(suggestionsSet);
  };

  const suggestions = getSuggestions(postItems, searchQuery);
  const trendingHashtags = getTrendingHashtags(postItems);

  const filteredPosts = postItems?.filter(
    (post) =>
      post.post.caption.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
      post.post.hashtags.some((hashtag) =>
        hashtag.toLowerCase().startsWith(searchQuery.toLowerCase())
      )
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <div>Error: {}</div>
      </div>
    );
  }

  if (postItems?.length === 0) {
    return <EmptyNotice message="No posts to show" />;
  }

  return (
    <div className="flex-1 overflow-y-auto p-5 flex flex-col items-center">
      <Input
        type="text"
        placeholder="Search by Caption or Hashtags (e.g. #food)"
        className="w-full max-w-xl p-2 border rounded focus:outline-none focus:border-blue-500"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setShowSuggestions(true);
        }}
        // onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
      />

      {showSuggestions && suggestions.length > 0 && (
        <div className="rounded-md bg-white shadow-lg w-full max-w-xl">
          <ul className="overflow-y-auto py-1 text-sm">
            {suggestions.slice(0, 5).map((suggestion, index) => (
              <li
                className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                key={index}
              >
                <div
                  className="flex items-center"
                  onMouseDown={() => {
                    setSearchQuery(suggestion);
                    setShowSuggestions(false);
                  }}
                >
                  <span>{suggestion}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <TrendingHashtag TrendingHashtags={trendingHashtags} />

      <div className="w-full max-w-xl">
        {(searchQuery === "" ? postItems : filteredPosts)?.map((post) => (
          <PostCard key={post.post.id} item={post} />
        ))}
      </div>
    </div>
  );
};

export default Home;
