import { Button } from "@/components/ui/button";
import {
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenu,
} from "@/components/ui/dropdown-menu";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ModifyPostModal from "./PostModifier.tsx";
import { useDeletePost } from "./useDeletePostMutation.tsx";
import { toast } from "@/components/ui/use-toast.ts";
import { useAuth } from "./AuthContext.tsx";
import { useNavigate } from "react-router-dom";
import { useProfile } from "./ProfileContext.tsx";

interface ConfigProps {
  hashtags: string[];
  mentions: string[];
  caption: string;
}

const PostConfigDropdown: React.FC<ConfigProps> = ({
  hashtags,
  mentions,
  caption,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalToDeleteOpen, setIsModalToDeleteOpen] = useState(false);

  const { mutate: deletepost } = useDeletePost();

  const { accessToken } = useAuth();

  const { postId } = useParams<{ postId: string }>();

  const { id: sessionProfileId } = useProfile();

  const navigate = useNavigate();

  const deletePost = () => {
    deletepost(
      { postId, accessToken },
      {
        onSuccess: () => {
          toast({
            variant: "constructive",
            title: "Deleted!",
          });
          setIsModalToDeleteOpen(false);
          navigate(`/app/profile/${sessionProfileId}`);
        },
        onError: (error) => {
          const err = error as { response?: any };
          toast({
            variant: "destructive",
            title: "Delete Failed",
            description: JSON.stringify(err.response.data),
          });
        },
      }
    );
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const handleOpenModalToDelete = () => {
    setIsModalToDeleteOpen(true);
    document.body.classList.add("modal-delete-open");
  };

  const handleSubmit = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  return (
    <DropdownMenu key="1">
      <DropdownMenuTrigger asChild>
        <Button
          className="rounded-full p-1 border border-gray-200 text-black hover:bg-gray-100 dark:hover:bg-gray-800"
          size="icon"
          variant="ghost"
        >
          Edit
          {/* <MoreHorizontalIcon className="w-4 h-4" /> */}
          <div className="flex items-center" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="w-36" side="bottom">
        <DropdownMenuItem onClick={handleOpenModalToDelete}>
          Delete
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleOpenModal}>Modify</DropdownMenuItem>
      </DropdownMenuContent>
      {isModalOpen && (
        <div className="modal h-screen">
          <div className="modal-content h-screen">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <ModifyPostModal
              onSubmit={handleSubmit}
              hashtags={hashtags}
              mentions={mentions}
              caption={caption}
            />
          </div>
        </div>
      )}

      {isModalToDeleteOpen && (
        <div
          className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
          id="modal-id"
        >
          <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
          <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
            <div className="">
              <div className="text-center p-5 flex-auto justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 -m-1 flex items-center text-black mx-auto"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-16 h-16 flex items-center text-black mx-auto"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <h2 className="text-xl font-bold py-4 ">Are you sure?</h2>
                <p className="text-sm text-gray-500 px-8">
                  Do you really want to delete your post? This process cannot be
                  undone
                </p>
              </div>
              <div className="p-3  mt-2 text-center space-x-4 md:block">
                <button
                  onClick={() => setIsModalToDeleteOpen(false)}
                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={deletePost}
                  className="mb-2 md:mb-0 bg-black border border-black px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-gray-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </DropdownMenu>
  );
};

export default PostConfigDropdown;

// function MoreHorizontalIcon(props: any) {
//   return (
//     <svg
//       {...props}
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke="currentColor"
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <circle cx="12" cy="12" r="1" />
//       <circle cx="19" cy="12" r="1" />
//       <circle cx="5" cy="12" r="1" />
//     </svg>
//   );
// }
