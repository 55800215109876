import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Profile, Post } from './useFetchHomePosts';
import config from '../../config';
import * as Sentry from '@sentry/react';

interface UserPosts {
    profile: Profile;
    posts: Post[];
}



export const useFetchProfilePosts = ({ profileId, accessToken } : { profileId: string | undefined, accessToken: string | null }) => {
    Sentry.captureMessage("Fetching profile posts", 'info');
    return useQuery({
        queryKey: ["fetchProfilePosts", profileId],
        queryFn: async () => {
            const response = await axios.get(`${config.backendUrl}/app/profile/${profileId}/posts`, 
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            return response.data as UserPosts;
        },
        enabled: !!accessToken,
    });
}