import React from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "../ui/toaster";

const Layout_2: React.FC = () => {
  return (
    <div>
      <header></header>
      <main>
        <div>
          <Outlet />
        </div>
      </main>
      <Toaster/>
            <footer></footer>
    </div>
  );
};

export default Layout_2;
