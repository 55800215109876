import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../config';
import * as Sentry from '@sentry/react';

export interface comment {
    content: string;
    created_at: string;
    profile: Profile;
}

export interface User {
    id: number;
    username: string;
    email: string;
}


export interface Profile {
    id: number;
    phone: string;
    bio: string;
    first_name: string;
    last_name: string;
    profile_picture: string;
    imageNumber: number
    user: User;
}

export interface Post {
    id: number;
    image: string;
    caption: string;
    hashtags: string[];
    mentions: string[];
    is_liked: boolean;
    likes_count: number;
    comments: comment[];
    created_at: string;
}

export interface HomePost {
    post: Post;
    profile: Profile;
}

export const useFetchHomePosts = (accessToken : string | null) => {
    Sentry.captureMessage('Fetching posts for homepage', 'info');
    return useQuery({
        queryKey: ["fetchHomePosts"],
        queryFn: async () => {
            const response = await axios.get(`${config.backendUrl}/app/posts/list`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                
            });
            return response.data as HomePost[];
        },
        enabled: !!accessToken,
    });
};