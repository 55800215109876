import React, { useState } from "react";
import { useAuth } from "./AuthContext.tsx";
import { toast } from "@/components/ui/use-toast";
import { useEditPost } from "./useEditPostMutation.tsx";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useProfile } from "./ProfileContext.tsx";

interface PostModifierProps {
  hashtags: string[];
  mentions: string[];
  caption: string;
  onSubmit: () => void;
}

const PostModifier: React.FC<PostModifierProps> = ({
  hashtags,
  mentions,
  caption,
  onSubmit,
}) => {
  const { mutate: editPost } = useEditPost();

  const { accessToken } = useAuth();

  const { id: sessionProfileId } = useProfile();

  const { postId } = useParams<{ postId: string }>();

  const navigate = useNavigate();

  const [postData, setPostData] = useState({
    hashtags: hashtags.join(" "),
    mentions: mentions.join(" "),
    caption: caption,
  });

  const handleInputsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    setPostData({
      ...postData,
      [id]: value,
    });
  };
  const handleEditPost = () => {
    return editPost(
      { postId, postData, accessToken },
      {
        onSuccess: () => {
          onSubmit();
          toast({
            variant: "constructive",
            title: "Modified!",
          });
          navigate(`/app/profile/${sessionProfileId}`);
        },
        onError: (error) => {
          const err = error as { response?: any };
          toast({
            variant: "destructive",
            title: "Upload Failed",
            description: JSON.stringify(err.response.data),
          });
        },
      }
    );
  };

  return (
    <section className="flex content-center container w-full mx-auto">
      <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden items-center">
        <div className="px-4 py-8">
          <div className="mt-4">
            <label
              htmlFor="hashtags"
              className="block text-sm font-medium text-gray-700"
            >
              Hashtags
            </label>
            <textarea
              id="hashtags"
              value={postData.hashtags}
              onChange={handleInputsChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="hashtags"
              className="block text-sm font-medium text-gray-700"
            >
              Mentions
            </label>
            <textarea
              id="mentions"
              value={postData.mentions}
              onChange={handleInputsChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="caption"
              className="block text-sm font-medium text-gray-700"
            >
              caption
            </label>
            <textarea
              id="caption"
              value={postData.caption}
              onChange={handleInputsChange}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <br></br>
          <div className="flex items-center justify-center">
            <div className="w-full">
              <label className="w-full text-white bg-[#050708] hover:bg-[#050708]/90 focus:ring-4 focus:outline-none focus:ring-[#050708]/50 font-medium rounded-lg text-sm px-5 py-2.5 flex items-center justify-center mr-2 mb-2 cursor-pointer">
                <button className="text-center ml-2" onClick={handleEditPost}>
                  Modify
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PostModifier;
