import { Card, CardContent } from "../ui/card";


const TrendingHashtags = ({
  TrendingHashtags,
}: {
  TrendingHashtags: Map<string, number>;
}) => {
  return (
    <Card className="bg-white dark:bg-[#1a1a1a] w-full max-w-xl mt-3">
      <CardContent className="space-y-4">
        <h3 className="text-xl font-semibold pt-2">10 Most Trending Hashtags</h3>
        <div className="flex flex-wrap gap-x-2 gap-y-3">
          {Array.from(TrendingHashtags).slice(0, 10).map(([key, value]) => (
            <div
              key={key}
              className="flex items-center rounded-full bg-gray-100 px-3 py-1 text-sm font-medium text-gray-900 dark:bg-gray-800 dark:text-gray-50"
            >
              <span className="text-blue-500 mr-1">{key}</span>
              <span className="text-gray-500">({value} {value > 1 ? "usages" : "usage"})</span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TrendingHashtags;
