import { Link } from "react-router-dom";

const ProfilePostCard: React.FC<{ image: string; postId: number }> = ({
  image,
  postId,
}) => {
  return (
    <Link to={`/app/post/${postId}`} className="">
      <img
        alt="Image"
        className="h-auto w-full aspect-square object-cover transition-transform duration-300 ease-in-out sm:hover:scale-105 rounded-md"
        height={300}
        src={image}
        width={300}
      />
    </Link>
  );
};

export default ProfilePostCard;
