import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";
import * as Sentry from '@sentry/react';

interface PostProps {
    hashtags: string;
    caption: string;
    mentions: string;
}


export const useEditPost = () => {
    Sentry.captureMessage('Post edition initiated', 'info');
    return useMutation({
        mutationFn: async ( {postId, postData, accessToken}: {postId: string | undefined, postData: PostProps, accessToken: string | null} ) => {
            return axios.put(`${config.backendUrl}/app/posts/${postId}/`, postData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data",
                },
            });
        },
    }) ;
};