import React from "react";
import Sidebar from "./SideBar";
import { Outlet } from "react-router-dom";
import { Toaster } from "../ui/toaster";

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <header></header>
      <main className="flex flex-1 bg-gray-100 dark:bg-gray-800">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          <Outlet />
        </div>
      </main>
      <Toaster />
      <footer></footer>
    </div>
  );
};

export default Layout;
