import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSignInMutation } from "./useSignInMutation";
import { useSignOutMutation } from "./useSignOutMutation";
import { useRefreshMutation } from "./useRefreshMutation";
import { useToast } from "@/components/ui/use-toast";
import Cookies from 'js-cookie';
import { useGoogleSignInMutation } from "./useGoogleSignInMutation";

interface AuthContextType {
  accessToken: string | null;
  googleSignIn: (credentialResponse: any) => void;
  signIn: (credentials: SignInCredentials) => void;
  signOut: () => void;
  refreshToken: () => void;
}

interface SignInCredentials {
  email: string;
  password: string;
}

export const authContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const navigate = useNavigate();

  const { mutate: signInMutation } = useSignInMutation();

  const { mutate: googleSignInMutation } = useGoogleSignInMutation();

  const { mutate: signOutMutation } = useSignOutMutation();

  const { mutate: refreshMutation } = useRefreshMutation();

  const { toast } = useToast();


  const googleSignIn = async (credential : string | undefined) => {
    googleSignInMutation(credential, {
      onSuccess: (data) => {
        setAccessToken(data.data.access);
        Cookies.set('refresh', data.data.refresh, { expires: 7 });
        navigate("/app/home");
      },
      onError: () => {
        toast({
          variant: "destructive",
          title: "Incorrect Email or Pasword",
        });
      },
    });
  }

  const signIn = (credentials: SignInCredentials) => {
    signInMutation(credentials, {
      onSuccess: (data) => {
        setAccessToken(data.data.access);
        Cookies.set('refresh', data.data.refresh, { expires: 7 });
        navigate("/app/home");
      },
      onError: () => {
        toast({
          variant: "destructive",
          title: "Incorrect Email or Pasword",
        });
      },
    });
  };

  const signOut = () => {
    const refresh = Cookies.get('refresh');
    signOutMutation(refresh, {
      onSuccess: () => {
        setAccessToken(null);
        Cookies.remove('refresh');
        navigate("/");
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const refreshToken = () => {
    const refresh = Cookies.get('refresh');
    refreshMutation(refresh, {
      onSuccess: (data) => {
        setAccessToken(data.data.access);
      },
      onError: () => {
        navigate("/");
      },
    });
  };

  useEffect(() => {
    if (Cookies.get('refresh')) {
      refreshToken();
    }
  }, []);

  return (
    <authContext.Provider
      value={{
        accessToken,
        googleSignIn,
        signIn,
        signOut,
        refreshToken,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext) as AuthContextType;
