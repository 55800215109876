import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../../config";


export const useGoogleSignInMutation = () => {
  return useMutation({
    mutationFn: async (id_token: string | undefined) => {
      return axios.post(`${config.backendUrl}/users/auth/google/`, {
        id_token: id_token,
      });
    },
  });
};
